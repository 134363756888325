<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <h3 class="mb-5">{{ $t("Upload exports") }}</h3>
        <v-row>
          <v-col cols="12">
            <v-btn outlined>{{ $t("Policies") }}</v-btn>
          </v-col>
          <v-row>
            <v-col cols="12">
              <v-btn class="" outlined>{{ $t("Damage claims") }}</v-btn>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn disabled>{{ $t("Upload") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        {{ $t("Sync with ActiveCampaign") }}
      </v-card-title>
      <v-expansion-panels v-model="panel" multiple flat>
        <v-expansion-panel v-for="(actie, i) in acties" :key="i">
          <v-expansion-panel-header class="font-weight-bold subtitle-1 pt-0">{{
            actie.title
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              v-model="selected[i]"
              :headers="headers"
              :items="actie.Presons"
              hide-default-footer
              item-key="name"
              show-select
            >
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: [],
      panel: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("First name"),
          align: "start",
          sortable: true,
          value: "FirstName",
        },
        {
          text: this.$t("Last name"),
          sortable: false,
          value: "LastName",
        },
        {
          text: this.$t("Address"),
          sortable: false,
          value: "Address",
        },
        {
          text: this.$t("Email"),
          sortable: false,
          value: "Email",
        },
        {
          text: this.$t("Date of birth"),
          sortable: false,
          value: "DateOfBirth",
        },
      ];
    },
    acties() {
      return [
        {
          title: this.$t("Naam van de actie"),
          Presons: [
            {
              FirstName: "voornaam",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
            {
              FirstName: "voornaam2",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
            {
              FirstName: "voornaam3",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
            {
              FirstName: "voornaam4",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
          ],
        },
        {
          title: this.$t("Naam van de actie"),
          Presons: [
            {
              FirstName: "voornaam",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
            {
              FirstName: "voornaam2",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
            {
              FirstName: "voornaam3",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
            {
              FirstName: "voornaam4",
              LastName: "achternaam",
              Address: "adres",
              Email: "email",
              DateOfBirth: "01/02/20",
            },
          ],
        },
      ];
    },
  },
};
</script>

<style>
</style>